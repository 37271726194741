import { useEffect, useState } from "react";
import { _fs } from "../../services/firebase/config";
import { FB_QUERYSNAPSHOT } from "../../types/firbase.types";
import { COLLECTIONS_TYPE } from "../../constants/firebase.constants";
import { RowProps } from "../../components/core/AppTable/types";
import {
  ADMIN_LOGS,
  ADMIN_LOGS_ACTIONS,
} from "../../types/adminLogs/adminLogs.types";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import { tsToDateTimeSimpleFormat } from "../../helpers/date-time";
import { AdminLogsPageAtoms } from "./AdminLogsPage.atoms";
import { useAtom } from "jotai";

export const useFirebaseStreamAdminLogs = () => {
  const [rows, setRows] = useState<any>([]);
  const [fetched, setFetched] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useAtom(AdminLogsPageAtoms.isModalOpen);
  const [logsData, setLogsData] = useAtom(AdminLogsPageAtoms.logsData);

  useEffect(() => {
    const unsubscribe = _fs
      .collection(COLLECTIONS_TYPE.adminLogs)
      .orderBy("createdAt", "desc")
      .onSnapshot((querySnapshot: FB_QUERYSNAPSHOT) => {
        let _rows: RowProps[] = [];
        let _row: RowProps = {} as RowProps;

        querySnapshot.forEach((doc) => {
          const { id, moreInfo, type, user, createdAt, client } =
            doc?.data() as ADMIN_LOGS;

          _row = {
            id: `${id} ${
              client ? `${client.clientId} ${client.clientName}` : ""
            } ${type} ${ADMIN_LOGS_ACTIONS[type]} ${
              user
                ? `${user.firstName} ${user.lastName} ${user.uid} ${user.email}`
                : ""
            } ${createdAt} ${tsToDateTimeSimpleFormat(createdAt)}`,
            cells: [
              id,
              ADMIN_LOGS_ACTIONS[type] || logsData?.type || "-",
              user.email || user.uid ? (
                <div>
                  <div>{user?.email || "-"}</div>
                  <div>({user.uid || "-"})</div>
                </div>
              ) : (
                "-"
              ),
              client?.clientName || "-",
              String(tsToDateTimeSimpleFormat(createdAt)),
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  paddingRight: 20,
                  cursor: "pointer",
                }}
              >
                <DocumentScannerIcon
                  onClick={() => {
                    setIsModalOpen(true);
                    setLogsData(doc?.data() as ADMIN_LOGS);
                  }}
                />
              </div>,
            ],
          };
          _rows.push(_row);
        });
        setRows(_rows);
        setFetched(true);
      });

    return () => unsubscribe();
  }, [fetched]);

  return fetched ? rows : null;
};
