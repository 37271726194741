import { useAtom, useAtomValue } from "jotai";
import { AppModal } from "../../../../components/core/AppModal/AppModal";
import { MEMBERS_ATOMS } from "../../MembersPage.Atoms";
import {
  StyleAppModalSingleStepContainer,
  StyledAppModalStepsContainer,
} from "../../../../components/shared/StyledComponents/StyledAppModalComponents";
import { AppModalMobileSteps } from "../../../../components/shared/AppModalMobileSteps/AppModalMobileSteps";
import { AppModalDesktopSteps } from "../../../../components/shared/AppModalDesktopSteps/AppModalDesktopSteps";
import { StyledAppModalDesktopStepTitle } from "../../../../components/shared/AppModalDesktopSteps/AppModalDesktopSteps.styles";
import { VIEW_MEMBER_MODAL_ATOMS } from "./ViewMemberModal.Atoms";
import { useEffect, useState } from "react";
import {
  COLLECTIONS_TYPE,
  FIRESTORE_FUNCTIONS,
} from "../../../../constants/firebase.constants";
import {
  StyledEmailIcon,
  StyledErrorChip,
  StyledIconValueContainer,
  StyledPhoneIphoneIcon,
  StyledTitleActionsContainer,
  StyledViewClickable,
  StyledViewContainer,
  StyledViewRow,
  StyledViewTitle,
} from "../ViewModals.styles";
import { MEMBERS } from "../../../../types/members/members.types";
import { getFirstValueFromFirebaseByColKeyVal } from "../../../../hooks/firebase/getFirstValueFromFirebaseByColKeyVal";
import { StyledPrimaryColorText } from "../../../../components/shared/StyledComponents/shared-styled-components";
import { tsToDayMonthYear } from "../../../../helpers/date-time";
import { AppSpinner } from "../../../../components/core/AppSpinner/AppSpinner";
import { useSelector } from "react-redux";
import { getClientSelector } from "../../../../redux/client/clientSlice";
import { VIEW_CHILD_MODAL_ATOMS } from "../ViewChildModal/ViewChildModal.Atoms";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { getNamePathFromGroups } from "../../Tabs/GroupsTab/GroupsTab.helper";
import CircleIcon from "@mui/icons-material/Circle";
import {
  getNextPaymentDate,
  getPaymentStatus,
  mapValidProductId,
} from "../../MemberPage.helper";
// import { Chip } from "@mui/material";
import { firebaseHttpsCallable } from "../../../../hooks/firebase/firebase.helper";
import { ChildrenDTO } from "../../MembersPage.types";
import { Skeleton } from "@mui/material";

const getRoles = (member: MEMBERS, clientId: string) => {
  const isAdmin = member?.isClientAdmin && member.isClientAdmin[clientId];

  let roles: any[] = isAdmin ? ["Admin"] : [];

  if (
    member?.roles &&
    member?.roles[clientId] &&
    Object.keys(member?.roles[clientId])?.length > 0
  ) {
    const rolesKeys = Object.keys(member?.roles[clientId]);
    if (rolesKeys && rolesKeys.length > 0) {
      rolesKeys.forEach((roleKey) => {
        if (Object.values(member.roles[clientId][roleKey])?.length > 0) {
          roles.push(roleKey);
        }
      });
    }
  }

  return `${roles?.join(", ")}`;
};

const getGroups = (member: MEMBERS, clientId: string) => {
  let subgroups: string[] = [];

  if (
    member?.roles &&
    member?.roles[clientId] &&
    Object.keys(member?.roles[clientId])?.length > 0
  ) {
    const rolesKeys = Object.keys(member?.roles[clientId]);
    if (rolesKeys && rolesKeys.length > 0) {
      rolesKeys.forEach((roleKey) => {
        if (Object.values(member.roles[clientId][roleKey])?.length > 0) {
          Object.keys(member.roles[clientId][roleKey]).map(
            (subgroup: string) => {
              subgroups.push(subgroup);
            }
          );
        }
      });
    }
  }

  return subgroups;
};

export const ViewMemberModal = () => {
  const [isViewMemberModalOpen, setIsViewMemberModalOpen] = useAtom(
    VIEW_MEMBER_MODAL_ATOMS.isViewMemberModalOpen
  );
  const [viewMemberId, setViewMemberId] = useAtom(
    VIEW_MEMBER_MODAL_ATOMS.viewMemberId
  );

  const clientGroupsAndSubGroups = useAtomValue(
    MEMBERS_ATOMS.clientGroupsAndSubGroups
  );

  const [isLoading, setIsLoading] = useState<boolean>();

  const [memberData, setMemberData] = useState<MEMBERS>();

  const [isViewChildModalOpen, setIsViewChildModalOpen] = useAtom(
    VIEW_CHILD_MODAL_ATOMS.isViewChildModalOpen
  );

  const [viewChildId, setViewChildId] = useAtom(
    VIEW_CHILD_MODAL_ATOMS.viewChildId
  );

  const memberSubscription = useAtomValue(MEMBERS_ATOMS.memberSubscription);

  const [children, setChildren] = useState<ChildrenDTO[] | undefined>(
    undefined
  );

  const client = useSelector(getClientSelector);

  const [rolesAndGroups, setRolesAndGroups] = useState<
    { [x: string]: string[] }[]
  >([]);

  const getRolesAndGroups = (member: MEMBERS, clientId: string) => {
    const _roles = member.roles[clientId];

    const mappedRolesAndGroups = Object.entries(_roles)
      .map(([key, subGroups]) => ({
        [key]: Object.keys(subGroups as Record<string, boolean>)
          .filter(
            (subGroup) => (subGroups as Record<string, boolean>)[subGroup]
          )
          .sort(),
      }))
      .sort((a, b) => Object.keys(a)[0].localeCompare(Object.keys(b)[0]));

    setRolesAndGroups(mappedRolesAndGroups);
  };

  const fetchMember = async (_viewMemberId: string) => {
    const _memberData = (await getFirstValueFromFirebaseByColKeyVal(
      COLLECTIONS_TYPE.members,
      "uid",
      _viewMemberId
    )) as MEMBERS;
    setIsLoading(false);
    setMemberData(_memberData);
  };

  const handleClose = () => {
    setIsViewMemberModalOpen(false);
    setViewMemberId(undefined);
    setMemberData(undefined);
  };

  const handleOpenChildModal = (childId: string) => {
    handleClose();
    setViewChildId(childId);
    setIsViewChildModalOpen(true);
  };

  const fetchChildren = () => {
    if (memberData?.childrenIds && memberData?.childrenIds?.length > 0) {
      firebaseHttpsCallable(FIRESTORE_FUNCTIONS.getChildrenByIds)({
        childrenIds: memberData?.childrenIds,
        clientId: client?.clientId,
      })
        .then((res: { data: any }) => {
          const _children = res?.data?.data || [];

          setChildren(_children);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setChildren([]);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    if (viewMemberId) {
      fetchMember(viewMemberId);
    }
  }, [viewMemberId]);

  useEffect(() => {
    if (memberData) {
      fetchChildren();
      getRolesAndGroups(memberData, String(client?.clientId));
    }
  }, [memberData]);

  if (!isViewMemberModalOpen) {
    return <></>;
  }

  return (
    <AppModal
      isOpen={isViewMemberModalOpen}
      closeModal={() => handleClose()}
      $customContentStyle={{ padding: 0 }}
      disableBackdropClick={false}
      disableEscapeKeyDown={false}
    >
      <StyledAppModalStepsContainer>
        <AppModalMobileSteps
          tabsLength={1}
          tabValue={0}
          titlesOfSteps={[
            <StyledTitleActionsContainer>
              Member profile
            </StyledTitleActionsContainer>,
          ]}
          hideStepsTitle={true}
        />
        <AppModalDesktopSteps
          tabs={[]}
          tabsTitle={""}
          OnChangeTabChange={() => {}}
          tabValue={0}
        />
        <StyleAppModalSingleStepContainer style={{ paddingBottom: 0 }}>
          <StyledAppModalDesktopStepTitle>
            <StyledTitleActionsContainer style={{ justifyContent: "start" }}>
              Member profile
            </StyledTitleActionsContainer>
          </StyledAppModalDesktopStepTitle>

          {isLoading && <AppSpinner />}

          {!isLoading && memberData && (
            <StyledViewContainer>
              <StyledViewRow>
                <StyledViewTitle>First name</StyledViewTitle>
                <StyledPrimaryColorText
                  style={{ fontSize: 18, fontWeight: 600 }}
                >
                  {memberData?.firstName}
                </StyledPrimaryColorText>
              </StyledViewRow>

              <StyledViewRow>
                <StyledViewTitle>Middle name</StyledViewTitle>
                <StyledPrimaryColorText
                  style={{ fontSize: 18, fontWeight: 600 }}
                >
                  {memberData?.middleName || "-"}
                </StyledPrimaryColorText>
              </StyledViewRow>

              <StyledViewRow>
                <StyledViewTitle>Last name</StyledViewTitle>
                <StyledPrimaryColorText
                  style={{ fontSize: 18, fontWeight: 600 }}
                >
                  {memberData?.lastName}
                </StyledPrimaryColorText>
              </StyledViewRow>

              <StyledViewRow>
                <StyledViewTitle>Gender</StyledViewTitle>
                <StyledPrimaryColorText
                  style={{ fontSize: 18, fontWeight: 600 }}
                >
                  {memberData?.gender || "-"}
                </StyledPrimaryColorText>
              </StyledViewRow>

              <StyledViewRow>
                <StyledViewTitle>Date of birth</StyledViewTitle>
                <StyledPrimaryColorText
                  style={{ fontSize: 18, fontWeight: 600 }}
                >
                  {memberData?.dateOfBirth
                    ? tsToDayMonthYear(memberData?.dateOfBirth)
                    : "-"}
                </StyledPrimaryColorText>
              </StyledViewRow>

              <StyledViewRow>
                <StyledViewTitle>Medical conditions</StyledViewTitle>
                <StyledPrimaryColorText
                  style={{ fontSize: 18, fontWeight: 600 }}
                >
                  {memberData?.medicalConditions || "-"}
                </StyledPrimaryColorText>
              </StyledViewRow>

              <StyledViewRow>
                <StyledViewTitle>Photo consent</StyledViewTitle>
                <StyledPrimaryColorText
                  style={{ fontSize: 18, fontWeight: 600 }}
                >
                  {memberData?.photoConsent === false && "No"}
                  {memberData?.photoConsent === true && "Yes"}
                  {!memberData?.photoConsent &&
                    memberData?.photoConsent !== false &&
                    "-"}
                </StyledPrimaryColorText>
              </StyledViewRow>

              <StyledViewRow>
                <StyledViewTitle>Payment status</StyledViewTitle>
                <StyledPrimaryColorText
                  style={{ fontSize: 18, fontWeight: 600 }}
                >
                  {getPaymentStatus(
                    memberData &&
                      memberSubscription &&
                      memberSubscription[memberData.uid]
                  )}
                </StyledPrimaryColorText>
              </StyledViewRow>

              <StyledViewRow>
                <StyledViewTitle>
                  Payment date
                  {memberSubscription &&
                    mapValidProductId(
                      memberSubscription[String(memberData?.uid)]?.productId
                    ) &&
                    getNextPaymentDate(memberSubscription, memberData.uid) !==
                      0 &&
                    getNextPaymentDate(memberSubscription, memberData.uid) <
                      Date.now() && <StyledErrorChip>overdue</StyledErrorChip>}
                </StyledViewTitle>
                <StyledPrimaryColorText
                  style={{ fontSize: 18, fontWeight: 600 }}
                >
                  {tsToDayMonthYear(
                    getNextPaymentDate(memberSubscription, memberData.uid)
                  ) || "-"}
                </StyledPrimaryColorText>
              </StyledViewRow>

              <StyledViewRow>
                <StyledViewTitle>Roles and groups</StyledViewTitle>
                <StyledPrimaryColorText
                  style={{ fontSize: 18, fontWeight: 600 }}
                >
                  {rolesAndGroups?.map((roleAndGroups, index) => (
                    <StyledPrimaryColorText
                      key={index}
                      style={{ fontSize: 18, fontWeight: 600 }}
                    >
                      {Object.keys(roleAndGroups).map((roleKeyName, index) => (
                        <StyledPrimaryColorText
                          key={index}
                          style={{
                            fontSize: 18,
                            fontWeight: 600,
                            marginBottom: 8,
                          }}
                        >
                          <StyledPrimaryColorText
                            style={{ fontSize: 16, marginInline: 4 }}
                          >
                            {roleKeyName}:
                          </StyledPrimaryColorText>
                          {roleAndGroups[roleKeyName].map((groupId, index) => (
                            <StyledPrimaryColorText
                              key={index}
                              style={{
                                marginInline: 16,
                                fontSize: 15,
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <CircleIcon
                                style={{ fontSize: 6, marginRight: 4 }}
                              />
                              {getNamePathFromGroups(
                                clientGroupsAndSubGroups,
                                groupId
                              )}
                            </StyledPrimaryColorText>
                          ))}
                        </StyledPrimaryColorText>
                      ))}
                    </StyledPrimaryColorText>
                  ))}
                </StyledPrimaryColorText>
              </StyledViewRow>

              <StyledViewRow>
                <StyledViewTitle>Contact</StyledViewTitle>
                <StyledPrimaryColorText
                  style={{ fontSize: 18, fontWeight: 600 }}
                >
                  <StyledIconValueContainer>
                    <StyledEmailIcon style={{ fontSize: 22 }} />
                    <span>{memberData?.email || "-"}</span>
                  </StyledIconValueContainer>
                </StyledPrimaryColorText>

                <StyledPrimaryColorText
                  style={{ fontSize: 18, fontWeight: 600 }}
                >
                  <StyledIconValueContainer>
                    <StyledPhoneIphoneIcon style={{ fontSize: 22 }} />
                    <span>{memberData?.phoneNumber || "-"}</span>
                  </StyledIconValueContainer>
                </StyledPrimaryColorText>
              </StyledViewRow>

              <StyledViewRow>
                <StyledViewTitle>Children</StyledViewTitle>
                {children === undefined && <Skeleton width={50} />}

                {children !== undefined && (
                  <>
                    {children?.length > 0 ? (
                      <StyledPrimaryColorText
                        style={{ fontSize: 18, fontWeight: 600 }}
                      >
                        {children.map((child, index) => {
                          return (
                            <StyledViewClickable
                              key={index}
                              onClick={() =>
                                handleOpenChildModal(String(child.childId))
                              }
                            >
                              <AccountCircleIcon />
                              <span>
                                {child.firstName} {child.lastName}
                              </span>
                            </StyledViewClickable>
                          );
                        })}
                      </StyledPrimaryColorText>
                    ) : (
                      <StyledPrimaryColorText
                        style={{ fontSize: 18, fontWeight: 600 }}
                      >
                        -
                      </StyledPrimaryColorText>
                    )}
                  </>
                )}
              </StyledViewRow>
            </StyledViewContainer>
          )}
        </StyleAppModalSingleStepContainer>
      </StyledAppModalStepsContainer>
    </AppModal>
  );
};
