import { Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import { getUser } from "../../redux/user/userSlice";
import { GlobalState } from "../../types/global";
import { APP_ROUTES } from "../../constants/routes.constant";
import { AppHeader } from "../core/AppHeader/AppHeader";
import { DashboardPage } from "../../pages/DashboardPage/DashboardPage";
import { ClientsPage } from "../../pages/ClientsPage/ClientsPage";
import { StyledAppLoggedInContainer } from "../../App.styles";
import { AdminLogsPage } from "../../pages/AdminLogsPage/AdminLogsPage";

export const YourzownAdminRoutes = () => {
  const user = useSelector((state: GlobalState) => getUser(state));

  const permissions = {
    client: Boolean(
      user?.yourzownPermissions?.clients?.view ||
        user?.yourzownPermissions?.clients?.edit
    ),
  };

  const isYourzownAdmin = user?.yourzownPermissions;

  if (!isYourzownAdmin) {
    return <></>;
  }

  return (
    <StyledAppLoggedInContainer>
      <AppHeader />
      <Routes>
        <Route
          path={APP_ROUTES.home}
          element={<Navigate to={APP_ROUTES.dashboard} />}
        />
        <Route path={APP_ROUTES.dashboard} element={<DashboardPage />} />
        {permissions.client && (
          <Route path={APP_ROUTES.clients} element={<ClientsPage />} />
        )}
        <Route path={APP_ROUTES.adminLogs} element={<AdminLogsPage />} />
        <Route path={APP_ROUTES.pageNotFound} element={<DashboardPage />} />
      </Routes>
    </StyledAppLoggedInContainer>
  );
};
