export enum COLLECTIONS_TYPE {
  colors = "colors",
  clients = "clients",
  members = "members",
  templates = "templates",
  clientTemplates = "clientTemplates",
  groups = "groups",
  roles = "roles",
  children = "children",
  contacts = "contacts",
  additionalProfileInfo = "additionalProfileInfo",
  products = "products",
  subscriptions = "subscriptions",
  clientClubDocs = "clientClubDocs",
  clubDocs = "clubDocs",
  locations = "locations",
  subGroupsContacts = "subGroupsContacts",
  clientSubGroupsContacts = "clientSubGroupsContacts",
  eventInvites = "eventInvites",
  events = "events",
  clientEvents = "clientEvents",
  eventPayments = "eventPayments",
  clientGroups = "clientGroups",
  buildVersions = "buildVersions",
  web = "web",
  adminLogs = "adminLogs",
}

export enum FIRESTORE_FUNCTIONS {
  subscribeToTopic = "functions-notifications-subscribeToTopic",
  getRolesAndSubGroups = "functions-client-getRolesAndSubGroups",
  inviteMember = "functions-member-inviteMember",
  updateMember = "functions-member-updateMember",
  sendInviteClient = "functions-client-inviteClient",
  updatePassword = "functions-auth-updatePassword",

  addClient = "functions-client-addClient",
  editClientData = "functions-client-editClientData",

  addEventTemplate = "functions-templates-addEventTemplate",
  editEventTemplate = "functions-templates-editEventTemplate",

  addPerformancePostTemplate = "functions-templates-addPerformancePostTemplate",
  editPerformancePostTemplate = "functions-templates-editPerformancePostTemplate",

  addQuickPostTemplate = "functions-templates-addQuickPostTemplate",
  editQuickPostTemplate = "functions-templates-editQuickPostTemplate",

  /** form posts functions */
  addGeneralPostTemplate = "functions-templates-addGeneralPostTemplate",
  editGeneralPostTemplate = "functions-templates-editGeneralPostTemplate",

  getGroupMembersCount = "functions-groups-getGroupsMembersCountByClientId",

  addGroupAndSubGroups = "functions-groups-addGroupAndSubGroups",
  deleteSubGroup = "functions-groups-deleteSubGroup",

  editClientBranding = "functions-client-editClientBranding",

  addRoleToClient = "functions-roles-addRoleToClient",
  editClientRole = "functions-roles-editClientRole",

  getRolesMemberCount = "functions-roles-getRolesMemberCount",
  deleteTemplate = "functions-templates-deleteTemplate",
  sendResetPasswordEmail = "functions-auth-sendResetPasswordEmail",
  resendInviteMemberEmail = "functions-member-resendInviteMemberEmail",
  deactivateMember = "functions-member-deactivateMember",
  reactivateMember = "functions-member-reactivateMember",

  addContact = "functions-contacts-addContact",
  updateContact = "functions-contacts-updateContact",
  deleteContact = "functions-contacts-deleteContact",

  addClubDoc = "functions-clubDocs-addClubDoc",
  updateClubDoc = "functions-clubDocs-updateClubDoc",
  deleteClubDoc = "functions-clubDocs-deleteClubDoc",

  createClientAccount = "functions-payments-createClientAccount",
  createClientAccountDashboardLink = "functions-payments-createClientAccountDashboardLink",
  addSubscriptionProduct = "functions-payments-addSubscriptionProduct",
  deleteSubscriptionProduct = "functions-payments-deleteSubscriptionProduct",
  updateDefaultPayment = "functions-payments-updateDefaultPayment",
  createOneTimePaymentLink = "functions-payments-createOneTimePaymentLink",

  addLocation = "functions-location-addLocation",
  updateLocation = "functions-location-updateLocation",
  updateDefaultClubLocation = "functions-location-updateDefaultClubLocation",
  deleteLocation = "functions-location-deleteLocation",

  getInvitationDetails = "functions-events-getInvitationDetails",
  attendingEvent = "functions-events-attendingEvent",
  notAttendingEvent = "functions-events-notAttendingEvent",

  getChildrenByIds = "functions-children-getChildrenByIds",
  getMemberWithChildren = "functions-children-getMemberWithChildren",

  updateChildById = "functions-children-updateChildById",
}
