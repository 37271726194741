import { useEffect, useState } from "react";
import { StyledPageContainer } from "../../components/shared/StyledComponents/StyledPageContainer";
import { AppSpinner } from "../../components/core/AppSpinner/AppSpinner";
import { AppTable } from "../../components/core/AppTable/AppTable";
import { RowProps } from "../../components/core/AppTable/types";
import { useFirebaseStreamAdminLogs } from "./useFirebaseStreamAdminLogs";
import { MoreInfoModal } from "./MoreInfoModal/MoreInfoModal";

export const AdminLogsPage = () => {
  const [isLoading, setIsLoading] = useState(true);

  const columns = [
    {
      cell: "ID",
    },
    {
      cell: "TYPE",
    },
    {
      cell: "ACTION BY (UID)",
    },
    {
      cell: "CLIENT",
    },
    {
      cell: "DATE",
    },
    {
      cell: (
        <div style={{ display: "flex", justifyContent: "end" }}>MORE INFO</div>
      ),
    },
  ];

  const rows: RowProps[] = useFirebaseStreamAdminLogs();

  useEffect(() => {
    rows && setIsLoading(false);
  }, [rows]);

  return (
    <>
      <StyledPageContainer>
        {isLoading ? (
          <AppSpinner />
        ) : (
          <>
            <AppTable
              columns={columns}
              rows={rows}
              showPagination={false}
              showTotalCount={false}
            />
          </>
        )}
      </StyledPageContainer>
      <MoreInfoModal />
    </>
  );
};
