import { Form, Formik, FormikErrors, FormikTouched } from "formik";
import * as Yup from "yup";
import {
  FORM_FIELDS_DISPLAY_STATES,
  FORM_FIELDS_TYPES,
  FORM_TEXT_INPUT_FIELD,
} from "../FormPostFields.types";
import { AppFormikField } from "../../../../../components/core/AppFormik/AppFormikField/AppFormikField";
import styled from "styled-components";
import { StyledAppModalSubmitButtonContainer } from "../../../../../components/shared/StyledComponents/StyledAppModalComponents";
import { AppButton } from "../../../../../components/core/AppButton/AppButton";
import { StyledPrimaryColorText } from "../../../../../components/shared/StyledComponents/shared-styled-components";
import { useAtom } from "jotai";
import {
  FORM_FIELD_ATOMS,
  TEMPLATES_ATOMS,
} from "../../AddEditTemplateModal.atoms";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

const schema = Yup.object({
  label: Yup.string().required("Required").max(1000, "Maximum length is 1000"),
});

interface FormTextInputValue {
  label: string;
}

const StyledAddAndCancelContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: 25,
  alignItems: "center",
  width: "100%",
  "@media only screen and (min-width: 1200px)": {
    flexDirection: "row",
    gap: 40,
  },
});

const defaultInitialValues = {
  label: "",
};

export const FormPostTextInput = () => {
  const [initialValues, setInitialValues] = useState(defaultInitialValues);

  const [fieldSelectType, setFieldSelectType] = useAtom(
    FORM_FIELD_ATOMS.fieldSelectType
  );
  const [displayState, setDisplayState] = useAtom(
    FORM_FIELD_ATOMS.displayState
  );

  const [formPostAdditionalFields, setFormPostAdditionalFields] = useAtom(
    TEMPLATES_ATOMS.formPostAdditionalFields
  );

  const [editingFieldIndex, setEditingFieldIndex] = useAtom(
    FORM_FIELD_ATOMS.editingFieldIndex
  );

  const resetForm = () => {
    setFieldSelectType(undefined);
    setDisplayState(FORM_FIELDS_DISPLAY_STATES.FIELDS_ROWS);
    setEditingFieldIndex(undefined);
    setInitialValues(defaultInitialValues);
  };

  const handleOnSubmit = (values: FormTextInputValue) => {
    if (editingFieldIndex !== undefined) {
      const newFormPostAdditionalFields = formPostAdditionalFields.map(
        (field, index) => {
          if (index === editingFieldIndex) {
            return {
              label: values.label,
              type: FORM_FIELDS_TYPES.textInput,
              required: true,
              id: formPostAdditionalFields[index]?.id || `ques_${uuidv4()}`,
            } as FORM_TEXT_INPUT_FIELD;
          }
          return field;
        }
      );
      setFormPostAdditionalFields([...newFormPostAdditionalFields]);
    } else {
      const textInputField: FORM_TEXT_INPUT_FIELD = {
        type: FORM_FIELDS_TYPES.textInput,
        label: values.label,
        required: true,
        id: `ques_${uuidv4()}`,
      };

      const newFormPostAdditionalFields = [
        ...formPostAdditionalFields,
        textInputField,
      ];
      setFormPostAdditionalFields([...newFormPostAdditionalFields]);
    }
    resetForm();
  };

  useEffect(() => {
    if (
      formPostAdditionalFields &&
      editingFieldIndex !== undefined &&
      formPostAdditionalFields[Number(editingFieldIndex)]?.label
    ) {
      setInitialValues({
        label: formPostAdditionalFields[Number(editingFieldIndex)].label,
      });
    }
  }, [formPostAdditionalFields, editingFieldIndex]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={(values: FormTextInputValue) => handleOnSubmit(values)}
    >
      {({
        errors,
        touched,
        values,
        submitCount,
        handleSubmit,
        handleChange,
        handleBlur,
      }) => {
        const _touched: FormikTouched<FormTextInputValue> = touched;
        const _errors: FormikErrors<FormTextInputValue> = errors;

        return (
          <Form
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "40vh",
            }}
          >
            <AppFormikField
              containerstyle={{ marginBottom: 40 }}
              name="label"
              isRequired={true}
              value={values.label || ""}
              label="Input label"
              errorMessage={
                (_touched.label && _errors.label) ||
                (submitCount > 0 && errors.label)
                  ? String(errors.label)
                  : ""
              }
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
            />

            <StyledAddAndCancelContainer>
              <StyledAppModalSubmitButtonContainer style={{ margin: 0 }}>
                <AppButton onClick={handleSubmit}>
                  {editingFieldIndex !== undefined ? "Edit" : "Add"}
                </AppButton>
              </StyledAppModalSubmitButtonContainer>
              <StyledPrimaryColorText
                style={{ cursor: "pointer" }}
                onClick={resetForm}
              >
                Cancel
              </StyledPrimaryColorText>
            </StyledAddAndCancelContainer>
          </Form>
        );
      }}
    </Formik>
  );
};
