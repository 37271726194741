import styled from "styled-components";
import { APP_COMMON_COLORS } from "../../../providers/AppThemeProvider";

export const StyledKey = styled.div({
  fontWeight: "bold",
});

export const StyledValue = styled.div({
  marginBottom: 12,
});

export const StyledDataContainer = styled.div({
  "@media only screen and (min-width: 1200px)": {
    display: "flex",
    gap: 100,
  },
});

export const StyledObjectContainer = styled.div({
  padding: 15,
  background: APP_COMMON_COLORS.dark[100],
});
