import { useAtom } from "jotai";
import { AppModal } from "../../../components/core/AppModal/AppModal";
import { AdminLogsPageAtoms } from "../AdminLogsPage.atoms";
import {
  ADMIN_LOGS,
  ADMIN_LOGS_ACTIONS,
} from "../../../types/adminLogs/adminLogs.types";
import { ReactNode } from "react";
import {
  StyledDataContainer,
  StyledKey,
  StyledObjectContainer,
  StyledValue,
} from "./MoreInfoModal.styles";
import { StyledAppModalStepsContainer } from "../../../components/shared/StyledComponents/StyledAppModalComponents";
import { AppModalMobileSteps } from "../../../components/shared/AppModalMobileSteps/AppModalMobileSteps";
import { AppModalDesktopSteps } from "../../../components/shared/AppModalDesktopSteps/AppModalDesktopSteps";
import { StyledAppModalDesktopStepTitle } from "../../../components/shared/AppModalDesktopSteps/AppModalDesktopSteps.styles";
import { tsToDateTimeSimpleFormat } from "../../../helpers/date-time";

const renderValue = (value: unknown): ReactNode => {
  if (typeof value === "string" || typeof value === "number") {
    return value;
  }
  if (Array.isArray(value)) {
    return value.join(", ");
  }
  if (typeof value === "object" && value !== null) {
    return JSON.stringify(value, null, 2);
  }
  return null; // For unsupported types
};

export const MoreInfoModal = () => {
  const [isModalOpen, setIsModalOpen] = useAtom(AdminLogsPageAtoms.isModalOpen);
  const [logsData, setLogsData] = useAtom(AdminLogsPageAtoms.logsData);

  if (!isModalOpen) {
    return <></>;
  }

  return (
    <AppModal isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)}>
      <StyledAppModalStepsContainer>
        <AppModalMobileSteps
          tabsLength={1}
          tabValue={0}
          hideStepsTitle
          titlesOfSteps={["More Info"]}
        />
        <AppModalDesktopSteps
          tabs={[]}
          tabsTitle={""}
          OnChangeTabChange={() => {}}
          tabValue={0}
        />

        <div>
          <StyledAppModalDesktopStepTitle>
            More Info
          </StyledAppModalDesktopStepTitle>

          <StyledDataContainer>
            <div>
              <StyledKey>Log Id</StyledKey>
              <StyledValue>{logsData?.id}</StyledValue>

              <StyledKey>Type</StyledKey>
              <StyledValue>
                {ADMIN_LOGS_ACTIONS[(logsData as ADMIN_LOGS)?.type] ||
                  logsData?.type}
              </StyledValue>

              <StyledKey>Created at</StyledKey>
              <StyledValue>
                {tsToDateTimeSimpleFormat(logsData?.createdAt || 0)}
              </StyledValue>

              <StyledKey>Action by (uid)</StyledKey>
              <StyledValue>
                {logsData?.user?.email || "-"}{" "}
                {logsData?.user?.uid ? `(${logsData?.user?.uid})` : ""}
              </StyledValue>

              <StyledKey>Client</StyledKey>
              <StyledValue>{logsData?.client?.clientName || "-"}</StyledValue>
            </div>
            <StyledObjectContainer>
              <pre>{JSON.stringify(logsData, null, 2)}</pre>
            </StyledObjectContainer>
          </StyledDataContainer>
        </div>
      </StyledAppModalStepsContainer>
    </AppModal>
  );
};
