import { useAtom, useSetAtom } from "jotai";
import * as Yup from "yup";
import {
  FORM_FIELD_ATOMS,
  TEMPLATES_ATOMS,
} from "../../AddEditTemplateModal.atoms";
import {
  FORM_FIELDS_DISPLAY_STATES,
  FORM_FIELDS_TYPES,
  FORM_SINGLE_CHOICE_FIELD,
} from "../FormPostFields.types";
import styled from "styled-components";
import { AppFormikField } from "../../../../../components/core/AppFormik/AppFormikField/AppFormikField";
import { InputAdornment } from "@mui/material";
import { StyledDoDisturbOnIcon } from "../../../../../components/shared/StyledComponents/shared-styled-components";
import { Formik, Form, FormikErrors, FormikTouched } from "formik";
import { AddIconWithLabel } from "../../../../../components/shared/AddIconWithLabel/AddIconWithLabel";
import { StyledAppModalSubmitButtonContainer } from "../../../../../components/shared/StyledComponents/StyledAppModalComponents";
import { AppButton } from "../../../../../components/core/AppButton/AppButton";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

const StyleForm = styled(Form)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "45vh",
  "@media only screen and (min-width: 1200px)": {
    height: "38vh",
  },
});
const StyleInputs = styled.div({
  overflowY: "auto",
  paddingTop: 8,
  paddingLeft: 18,
  paddingRight: 15,
  marginBottom: 20,
  minHeight: 280,
});

const StyledAddAndCancelContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: 25,
  alignItems: "center",
  width: "100%",
  "@media only screen and (min-width: 1200px)": {
    flexDirection: "row",
    gap: 40,
  },
});

const StyledCancel = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: 5,
  color: theme.colorPrimary,
  cursor: "pointer",
  fontWeight: 600,
  "&:hover": {
    textDecoration: "underline",
  },
}));

interface FormPostSingleChoiceValue {
  label: string;
  options: string[];
}
const schema = Yup.object({
  label: Yup.string().required("Required"),
});

const defaultInitialValues = {
  label: "",
  options: [""],
};

export const FormPostSingleChoice = () => {
  const setDisplayState = useSetAtom(FORM_FIELD_ATOMS.displayState);
  const setFieldSelectType = useSetAtom(FORM_FIELD_ATOMS.fieldSelectType);
  const [formPostAdditionalFields, setFormPostAdditionalFields] = useAtom(
    TEMPLATES_ATOMS.formPostAdditionalFields
  );

  const [initialValues, setInitialValues] = useState(defaultInitialValues);

  const [editingFieldIndex, setEditingFieldIndex] = useAtom(
    FORM_FIELD_ATOMS.editingFieldIndex
  );

  const resetForm = () => {
    setFieldSelectType(undefined);
    setDisplayState(FORM_FIELDS_DISPLAY_STATES.FIELDS_ROWS);
    setEditingFieldIndex(undefined);
    setInitialValues(defaultInitialValues);
  };

  const handleOnSubmit = (values: FormPostSingleChoiceValue) => {
    const dynamicValidationSchema = Yup.object().shape({
      label: Yup.string().required("Required"),
      options: Yup.array()
        .of(Yup.string().required("Option is required"))
        .min(1, "At least one option is required"),
    });

    /** edit singleChoiceField */
    if (editingFieldIndex !== undefined) {
      const newFormPostAdditionalFields = formPostAdditionalFields.map(
        (field, index) => {
          if (index === editingFieldIndex) {
            return {
              label: values.label,
              type: FORM_FIELDS_TYPES.singleChoice,
              required: true,
              options: values.options,
              id: formPostAdditionalFields[index]?.id || `ques_${uuidv4()}`,
            } as FORM_SINGLE_CHOICE_FIELD;
          }
          return field;
        }
      );
      dynamicValidationSchema
        .validate(values, { abortEarly: false })
        .then(() => {
          setFormPostAdditionalFields([...newFormPostAdditionalFields]);
          resetForm();
        })
        .catch((errors) => {
          return;
        });
    } else {
      /** add new singleChoiceField*/
      const singleChoiceField: FORM_SINGLE_CHOICE_FIELD = {
        type: FORM_FIELDS_TYPES.singleChoice,
        label: values.label,
        options: values.options,
        required: true,
        id: `ques_${uuidv4()}`,
      };

      dynamicValidationSchema
        .validate(values, { abortEarly: false })
        .then(() => {
          setFormPostAdditionalFields([
            ...formPostAdditionalFields,
            singleChoiceField,
          ]);
          resetForm();
        })
        .catch((errors) => {
          return;
        });
    }
  };

  useEffect(() => {
    if (
      formPostAdditionalFields &&
      editingFieldIndex !== undefined &&
      formPostAdditionalFields[Number(editingFieldIndex)]?.label
    ) {
      const currentValues: FORM_SINGLE_CHOICE_FIELD = {
        ...(formPostAdditionalFields[
          Number(editingFieldIndex)
        ] as FORM_SINGLE_CHOICE_FIELD),
      };

      setInitialValues({
        label: currentValues.label,
        options: [...(currentValues?.options || defaultInitialValues.options)],
      });
    }
  }, [formPostAdditionalFields, editingFieldIndex]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={(values: FormPostSingleChoiceValue) => handleOnSubmit(values)}
    >
      {({
        errors,
        touched,
        values,
        submitCount,
        handleSubmit,
        handleBlur,
        setFieldValue,
        setErrors,
      }) => {
        const _touched: FormikTouched<FormPostSingleChoiceValue> = touched;
        const _errors: FormikErrors<FormPostSingleChoiceValue> = errors;

        const handleOptionValueOnChange = (e: string, index: number) => {
          const newOptions = [...values.options];
          newOptions[index] = e;
          setFieldValue("options", newOptions);
        };

        const customHandleChange = (name: string, value: string) => {
          setFieldValue(name, value);
        };

        return (
          <StyleForm>
            <StyleInputs>
              <AppFormikField
                containerstyle={{ marginBottom: 8 }}
                name="label"
                isRequired={true}
                value={values.label || ""}
                label="Single Choice label"
                errorMessage={
                  (_touched.label && _errors.label) ||
                  (submitCount > 0 && errors.label)
                    ? String(errors.label)
                    : ""
                }
                onBlur={handleBlur}
                onChange={(e) => customHandleChange("label", e.target.value)}
                type="text"
              />

              {values?.options?.map((option: string, index: number) => {
                return (
                  <AppFormikField
                    key={index}
                    containerstyle={{ marginBottom: 8 }}
                    isRequired={true}
                    name={`options[${index}]`}
                    value={values.options[index] || ""}
                    label={`Option ${index + 1}`}
                    errorMessage={
                      submitCount > 0 && !values.options[index]
                        ? "Required"
                        : ""
                    }
                    onChange={(e) =>
                      handleOptionValueOnChange(e.target.value, index)
                    }
                    type="text"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {index > 0 && (
                            <StyledDoDisturbOnIcon
                              onClick={() => {
                                const newOptions = [...values.options];
                                newOptions.splice(index, 1);
                                setFieldValue("options", newOptions);
                              }}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                );
              })}

              <AddIconWithLabel
                label="Add Option"
                onClick={() =>
                  setFieldValue("options", [...values.options, ""])
                }
              />
            </StyleInputs>
            <StyledAddAndCancelContainer style={{ paddingBottom: 24 }}>
              <StyledAppModalSubmitButtonContainer style={{ margin: 0 }}>
                <AppButton onClick={handleSubmit}>
                  {editingFieldIndex !== undefined ? "Edit" : "Add"}
                </AppButton>
              </StyledAppModalSubmitButtonContainer>
              <StyledCancel onClick={resetForm}>Cancel</StyledCancel>
            </StyledAddAndCancelContainer>
          </StyleForm>
        );
      }}
    </Formik>
  );
};
