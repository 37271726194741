import { Dayjs } from "dayjs";

export const tsToDayMonthYear = (ts?: number) => {
  if (!ts) return "";
  const date = new Date(ts);
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};

export const dayJsToTs = (dayjs: Dayjs): number => {
  const day = Number(dayjs.toDate().getDate());
  const month = Number(dayjs.toDate().getMonth());
  const year = Number(dayjs.toDate().getFullYear());
  const newDate = new Date(year, month, day);
  return newDate.getTime();
};

/** time stamp to i.e 06/05/2023 05:29 */
export const tsToDateTimeSimpleFormat = (timeStamp: number) => {
  const date = new Date(timeStamp);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const formattedDate = `${day.toString().padStart(2, "0")}/${month
    .toString()
    .padStart(2, "0")}/${year} ${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;
  return formattedDate;
};
