import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { SignInPage } from "./pages/SignInPage/SignInPage";
import { Provider, useDispatch, useSelector } from "react-redux";
import { store } from "./redux/store";
import { AppThemeProvider } from "./providers/AppThemeProvider";
import { useEffect } from "react";
import { firebaseConfig } from "./services/firebase/config";
import { setUserData, setUserIsLoggedIn } from "./redux/user/userSlice";
import { AppSpinner } from "./components/core/AppSpinner/AppSpinner";
import { ToastContainer, toast } from "react-toastify";
import { APP_ROUTES } from "./constants/routes.constant";
import { getFirstValueFromFirebaseByColKeyVal } from "./hooks/firebase/getFirstValueFromFirebaseByColKeyVal";
import { COLLECTIONS_TYPE } from "./constants/firebase.constants";
import { USER_STATE } from "./types/user/user.types";
import firebase from "firebase/compat/app";
import "firebase/compat/messaging";
import { SignUpPage } from "./pages/SignUpPage/SignUpPage";
import { useSetClientData } from "./hooks/useSetClientData/useSetClientData";
import { YourzownAdminRoutes } from "./components/routes/YourzownAdminRoutes";
import { ClientRoutes } from "./components/routes/ClientRoutes";
import { getClientSelector } from "./redux/client/clientSlice";
import { ForgetPasswordPage } from "./pages/ForgetPassword/ForgetPasswordPage";
import { ResetPasswordPage } from "./pages/ResetPasswordPage/ResetPasswordPage";
import { APP_TOASTIFY } from "./styles/styles.constants";
import { useFirebaseLogoutUser } from "./hooks/firebase/useFirebaseLogoutUser";
import { useUserIdentity } from "./hooks/useUserIdentity/useUserIdentity";
import { DownloadMobileAppsPage } from "./pages/DownloadMobileAppsPage/DownloadMobileAppsPage";
import { setFirebaseFunctionsPort } from "./hooks/firebase/firebase.helper";
import { AttendEventPage } from "./pages/AttendEventPage/AttendEventPage";

export const App = () => {
  useSetClientData();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const pathname = location.pathname;

  const client = useSelector(getClientSelector);
  const logout = useFirebaseLogoutUser();
  const { isLoggedIn, user } = useUserIdentity();
  const LOGOUT_REQUIRED_ROUTES = [APP_ROUTES.signUp, APP_ROUTES.resetPassword];

  useEffect(() => {
    if (LOGOUT_REQUIRED_ROUTES.includes(pathname)) {
      logout();
    }
  }, [pathname]);

  useEffect(() => {
    firebase.initializeApp(firebaseConfig).firestore();
    setFirebaseFunctionsPort();
    const unsubscribe = firebase
      .auth()
      .onAuthStateChanged(async (_user: any) => {
        const user = _user?.multiFactor.user;

        if (user) {
          if (LOGOUT_REQUIRED_ROUTES.includes(pathname)) {
            logout();
            return;
          }

          const userProfile = (await getFirstValueFromFirebaseByColKeyVal(
            COLLECTIONS_TYPE.members,
            "uid",
            String(user.uid)
          )) as USER_STATE;

          const isClientAdmin = Object.values(
            userProfile?.isClientAdmin || {}
          )?.filter((isClientAdmin) => isClientAdmin);

          if (
            !(
              Boolean(isClientAdmin?.length) || userProfile?.yourzownPermissions
            )
          ) {
            logout();
            window.location.href =
              "https://yourzown-development.web.app/yourzown-mobile-app";
            toast("Please use our mobile app to sign in", APP_TOASTIFY.ERROR);
            return;
          }

          dispatch(setUserIsLoggedIn({ isLoggedIn: true }));
          dispatch(
            setUserData({
              uid: user.uid,
              email: user.email,
              firstName: userProfile.firstName,
              lastName: userProfile.lastName,
              clientsIds: userProfile.clientsIds,
              isClientAdmin: userProfile.isClientAdmin,
              roles: userProfile.roles,
              createdAt: userProfile.createdAt,
              profilePictureUrl: userProfile.profilePictureUrl,
              yourzownPermissions: userProfile.yourzownPermissions,
            })
          );
          if (
            [APP_ROUTES.signIn, APP_ROUTES.forgetPassword].includes(pathname)
          ) {
            navigate(APP_ROUTES.dashboard);
          }
          return;
        }

        logout();
        return;
      });

    return () => unsubscribe();
  }, [pathname]);

  if (isLoggedIn === undefined) {
    return <AppSpinner />;
  }

  if (isLoggedIn === true) {
    const isYourzownAdmin = user.yourzownPermissions;

    if (isYourzownAdmin) {
      return <YourzownAdminRoutes />;
    }

    if (client?.branding) {
      return <ClientRoutes />;
    }
  }

  return (
    <Routes>
      <Route
        path={APP_ROUTES.home}
        element={<Navigate to={APP_ROUTES.signIn} />}
      />
      <Route path={APP_ROUTES.signIn} element={<SignInPage />} />
      <Route path={APP_ROUTES.attendEvent} element={<AttendEventPage />} />
      <Route path={APP_ROUTES.signUp} element={<SignUpPage />} />
      <Route
        path={APP_ROUTES.forgetPassword}
        element={<ForgetPasswordPage />}
      />
      <Route path={APP_ROUTES.resetPassword} element={<ResetPasswordPage />} />
      <Route path={APP_ROUTES.pageNotFound} element={<SignInPage />} />
      <Route
        path={APP_ROUTES.downloadMobileApps}
        element={<DownloadMobileAppsPage />}
      />
    </Routes>
  );
};

export const AppWrapper = () => {
  return (
    <Provider store={store}>
      <AppThemeProvider>
        <Router>
          <App />
          <ToastContainer />
        </Router>
      </AppThemeProvider>
    </Provider>
  );
};
