import { CLIENT_STATE } from "../client/client.types";
import { USER_STATE } from "../user/user.types";

export enum ADMIN_LOG_TYPE {
  addClient = "addClient",
  editClient = "editClient",
  inviteMember = "inviteMember",
  editMember = "editMember",
}
export interface ADMIN_LOGS {
  id: string;
  client: CLIENT_STATE;
  type: ADMIN_LOG_TYPE;
  createdAt: number;
  user: USER_STATE;
  moreInfo: any;
}

export enum ADMIN_LOGS_ACTIONS {
  addClient = "Add client",
  editClient = "Edit client",
  inviteMember = "Invite member",
  editMember = "Edit member",
}
